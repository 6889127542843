.ant-btn.voice-button {
  display: flex;
  position: fixed;
  height: 64px !important;
  width: 64px;
  right: 50px;
  bottom: 20px;
  border-radius: 32px;
}

.voice-mobile-btn {
  @media screen and (max-width: 992px) {
    bottom: 150px !important;
  }
}

.voice-modal {
  position: fixed;
  display: flex;
  right: 0;
  bottom: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.voice-content {
  background: #fff;
  padding: 16px;
  border-radius: 16px !important;
  z-index: 1001;
  position: fixed;
  width: 468px;
  bottom: 0;
  right: 0;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    left: 0;
    right: 0;
    width: unset;
  }
  .voice-title {
    color: var(--text-100, #292b2e);
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .voice-caption {
    color: var(--text-100, #292b2e);
    /* Body */
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.269px;
  }

  .voice-list {
    color: var(--text-100, #292b2e);
    /* Body */
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.269px;
    margin-top: 0;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .voice-status {
    margin-top: auto;
    margin-bottom: 20px;
    max-width: 354px;
    color: var(--text-50, #505762);
    /* Body */
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.269px;
  }
  .voice-form {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--accent-10, #d1e3ff);
  }
  .voice-footer {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }
  .voice-alert {
    color: red;
  }
  .ant-btn.voice-cancel {
    padding-left: 0;
    display: inline-flex;
    height: 26px;
    font-family: 'Work Sans', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .italic {
    font-style: italic;
  }
}
