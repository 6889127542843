.update-app-modal {
  .ant-modal .ant-modal-content {
    padding: 32px 24px;

    @media (max-width: 500px) {
      padding: 16px;
    }
  }
  .ant-modal {
    max-width: 650px;
    width: calc(100% - 3em) !important;

    .modal-body {
      width: 100%;
      margin-bottom: 16px;

      display: flex;
      flex-direction: column;
      gap: 8px;

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & h1 {
          margin: 0px;
          font: normal normal 600 20px/30px 'Work Sans';
          height: fit-content;
        }
        .update-icon {
          max-height: 40px;
        }
      }

      &-text-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        & p {
          margin: 0px;
          font: normal normal 500 16px/24px 'Work Sans';
        }
      }
    }

    .modal-footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .ant-btn {
        font: normal normal 500 22px/26px 'Work Sans';
        border: none;
        box-shadow: none;
        border-radius: 15px;
        height: 50px;
        width: 100%;
      }

      .update-btn {
        color: white;
      }
      .cancel-btn {
        color: #1677ff;
        border: 1.8px solid #1677ff;
      }
    }
  }
}
