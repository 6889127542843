.modalBody {
  font: normal normal 400 14px/18px 'Work Sans';
  .name {
    font-weight: 500;
  }
  margin-bottom: 20px;
}

.button {
  width: 153px;
}
